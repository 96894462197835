<template>
	<div>
		<b-card class="mr-1 p-1" no-body>
			<b-row>
				<b-col class="d-flex align-items-center" cols="10">
					<b-nav pills class="m-0" style="gap: 1rem">
						<b-nav-item
							:to="{ name: 'brain-specimens-colors' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Colores
						</b-nav-item>
						<!-- <b-nav-item
							:to="{ name: 'brain-specimens-diseases' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Enfermedades
						</b-nav-item> -->
						<b-nav-item
							:to="{ name: 'brain-specimens-breeder' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Criadores
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'brain-specimens-race' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Raza
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'brain-specimens-measure' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Medida
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'brain-specimens-status' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Estados
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'brain-specimens-days-without-topes' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Dias sin topar
						</b-nav-item>
					</b-nav>
				</b-col>
				<b-col class="d-flex justify-content-end" style="gap: 1rem">
					<b-button
						v-if="$route.name == 'brain-specimens-race'"
						@click="childAction = 'create-race'"
						variant="success"
					>
						Crear Raza
					</b-button>
					<b-button
						v-if="$route.name == 'brain-specimens-measure'"
						@click="childAction = 'create-measure'"
						variant="success"
					>
						Crear Medida
					</b-button>
					<!-- <b-button
						v-if="$route.name == 'brain-specimens-status'"
						@click="childAction = 'create-status'"
						variant="success"
					>
						Crear Estado
					</b-button> -->
				</b-col>
			</b-row>
		</b-card>
		<hr />
		<router-view :action="childAction" @actionDone="childAction = ''" @blocked="actionBlocked = true" />
	</div>
</template>

<script>
export default {
	data: () => ({
		childAction: "",
		actionBlocked: false,
	}),
	watch: {
		"$route.name"() {
			this.actionBlocked = false
		},
	},
}
</script>
